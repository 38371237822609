import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/josh/Code/hub/joshayoung_website/src/components/layout-markdown.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Tips`}</h1>
    <p>{`These are some tips for development that I have found helpful. These also serve as helpful future reminders.`}</p>
    <ul>
      <li parentName="ul">{`Do not add your IDE files to your repos .gitignore file. Instead add them to a global .gitignore file.`}</li>
      <li parentName="ul">{`Before writing any code, build a architectural design with a diagramming tool using (UML, Entity Diagram, etc.) to insure you are programming the right thing.`}</li>
      <li parentName="ul">{`Sync with your team on a continual basis through the progress of your work by opening small pull requests to get their feedback.`}</li>
      <li parentName="ul">{`Never push production code to master unless it has good tests (unit, integration) backing that code.`}</li>
      <li parentName="ul">{`Before building out a new feature test it with a "spike" / "discovery".`}
        <ul parentName="li">
          <li parentName="ul">{`This discovery work is typically code that you build out quickly that has no tests. It provides a quick way to develop a workable/feasible plan.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Programming Cases:`}
        <ul parentName="li">
          <li parentName="ul">{`kebab-case`}</li>
          <li parentName="ul">{`camelCase`}</li>
          <li parentName="ul">{`PascalCase`}</li>
          <li parentName="ul">{`snake_case`}</li>
        </ul>
      </li>
      <li parentName="ul">{`When naming a method, use a name that signifies either 'What It Does' or 'What It Is'.`}
        <ul parentName="li">
          <li parentName="ul">{`Do not use a method name that signifies how it does it.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Make sure your editor displays trailing white space. This will make your diffs easier and prevent unnecessary changes.`}</li>
      <li parentName="ul">{`When building a mobile app, do not let the API's design determine how your build your mobile app's model(s).`}</li>
      <li parentName="ul">{`When writing a ticket for a kanban board (i.e. Jira, Trello, etc) in a team environment, include enough details in the ticket so that any one on the team can pull the ticket without having to consult anyone else.`}</li>
      <li parentName="ul">{`When using an external service (library, API, etc), try not to include the language of that project inside your application so that you can easily switch library's or APIs in the future without having to change your model/service names.`}</li>
      <li parentName="ul">{`Do not use exceptions for flow control.`}</li>
      <li parentName="ul">{`Before you start debugging an issue, always ask the simple questions first: "is the plugin initialized correctly", "am I calling the right function", is my element hidden, etc.`}</li>
      <li parentName="ul">{`If your code base is suddenly failing and you cannot determine why, find the last commit where it worked and see what has changed in each commit since. You should be able to `}<inlineCode parentName="li">{`git checkout [hash of commit]`}</inlineCode>{` to see if it worked at that point.`}</li>
    </ul>
    <h3>{`Maui / Xamarin`}</h3>
    <ul>
      <li parentName="ul">{`If you add a page as a singleton and have a form it it, it will keep the data you type in the form everytime you re-open the apge.`}</li>
      <li parentName="ul">{`Having an initialize method keeps most of the code for the vm out of the constructor. This is recommended practice by Microsoft, and allows for async methods.`}</li>
      <li parentName="ul">{`The wrong order of DI classes in Xamrin will cause the app to crash for no reason (sometimes it will not tell you, the app will just close).`}</li>
      <li parentName="ul">{`if x:Class it not correct, the xaml page will be blank!`}</li>
      <li parentName="ul">{`On IOS, if you have the ‘Entry’'s `}<inlineCode parentName="li">{`MaxLength`}</inlineCode>{` set a a number that is smaller than the length of the value that is binding to, it will cause a crash on IOS (at least if it is in a CarouselView). This same error does not occur in Android. Error: “Value cannot be null. (Parameter 'fromPosition’)”.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      